/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO } from '@core';
import { WorkshopPage } from '@composite';

export default () => (
  <Layout>
    <StaticSEO pageId='finnoscore' />
    <WorkshopPage />
  </Layout>
);
